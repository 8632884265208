<template>
    <div class="grafica" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH345078589");
export default {
    props:{
        info_grafica:{
            type: Array,
            required: true,
        },
    },
    mounted() {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
        chart.data = this.info_grafica;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = false;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "periodo";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.title.text = "Periodo";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.title.text = "Porcentaje %";

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "periodo";
        lineSeries.dataFields.valueY = "porcentaje";
        lineSeries.tooltipText = "[bold][/]\n[font-size:14px]{categoryX}: [bold]{valueY}%[/]";
        lineSeries.fillOpacity = .9;
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.fill = "color";
        lineSeries.propertyFields.stroke = "color";
        let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 3;
        bullet.circle.fill = am4core.color("#fff");
        bullet.circle.strokeWidth = 3;

        chart.cursor = new am4charts.XYCursor();

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer;       

        this.chart = chart;
    },
    beforeDestroy() {
        if (this.chart){
            this.chart.dispose();
        }
  }
}
</script>

<style scoped>
.grafica {
  width: 100%;
  height: 500px;
}
</style>
