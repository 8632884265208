<template>
    <div class="grafica" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';
import 'moment-timezone';
am4core.useTheme(am4themes_animated);
am4core.addLicense("CH345078589");
export default {
    props:{
        info_grafica:{
            type: Array,
            required: true,
        },
         indicador_id:{
            type: Number,
            required: true,
        }
    },
    mounted() {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
        var data = [];  
        this.info_grafica.forEach(function(element) {
            let fecha = element.periodo;
            let partes = fecha.split("-");
            let mes = partes[0];
            let anio = partes[1];
            var f = new Date(anio+'-'+mes+'-01T00:00:00');
            var date = moment(f).format("YYYY-MM");
            data.push({
                periodo:date,
                tuxtla:  element.tuxtla,
                mexico:  element.mexico,
                porcentaje: element.porcentaje*100
            });
        });
        chart.data = data;
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.dataFields.category = "periodo";
        dateAxis.title.text = "Mes / Año";
        dateAxis.title.fontSize = 17;
        dateAxis.title.fontWeight  = "bold";
        dateAxis.dateFormats.setKey("month", "[font-size: 12px]MM-yyyy");
        dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy");
        var label = dateAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 200;
        label.tooltipText = "Mes / Año";
        dateAxis.events.on("sizechanged", function(ev) {
        var axis = ev.target;
        var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
        if (cellWidth < axis.renderer.labels.template.maxWidth) {
            axis.renderer.labels.template.rotation = -45;
            axis.renderer.labels.template.horizontalCenter = "right";
            axis.renderer.labels.template.verticalCenter = "middle";
        }
        else {
            axis.renderer.labels.template.rotation = 0;
            axis.renderer.labels.template.horizontalCenter = "middle";
            axis.renderer.labels.template.verticalCenter = "top";
        }
        });


        var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = "Delitos";
        valueAxis1.title.fontSize = 17;
        valueAxis1.title.fontWeight  = "bold";
        
        var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.title.text = "Porcentaje %";
        valueAxis2.title.fontSize = 17;
        valueAxis2.title.fontWeight  = "bold";
        valueAxis2.syncWithAxis = valueAxis1;

        var seriesVal1 = chart.series.push(new am4charts.ColumnSeries());
        seriesVal1.name = "Tasa de delitos por cada 100 mil habitantes en el Municipio de Tuxtla Gutiérrez";
        seriesVal1.dataFields.valueY = "tuxtla";
        seriesVal1.dataFields.dateX = "periodo";
        
        var columnTemplateVal1 = seriesVal1.columns.template;
        columnTemplateVal1.tooltipText = "{dateX.formatDate('MM-yyyy')}: {valueY.formatNumber('#.0')}";
        columnTemplateVal1.fillOpacity = .8;
        columnTemplateVal1.strokeOpacity = 0;
        columnTemplateVal1.fill = am4core.color("#8AC926");

        var seriesVal2 = chart.series.push(new am4charts.ColumnSeries());
        seriesVal2.name = "Tasa de delitos por cada 100 mil habitantes en México";
        seriesVal2.dataFields.valueY = "mexico";
        seriesVal2.dataFields.dateX = "periodo";
       
        var columnTemplateVal2 = seriesVal2.columns.template;
        columnTemplateVal2.tooltipText = "{dateX.formatDate('MM-yyyy')}: {valueY.formatNumber('#.0')}";
        columnTemplateVal2.fillOpacity = .8;
        columnTemplateVal2.strokeOpacity = 0;
        columnTemplateVal2.fill = am4core.color("#1982C4");

        var series3 = chart.series.push(new am4charts.LineSeries());
        series3.name = "Tasa en Tuxtla Gutiérrez / Tasa en México (%)";
        series3.dataFields.valueY = "porcentaje";
        series3.dataFields.dateX = "periodo";
        series3.stroke = am4core.color("#FFCA3A");
        series3.strokeWidth = 4;
        series3.propertyFields.strokeDasharray = "lineDash";
        series3.tooltip.label.textAlign = "middle";

        series3.yAxis = valueAxis2;


        var bullet = series3.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#FFC000");
        bullet.tooltipText = "[#fff font-size: 15px] {dateX.formatDate('MM-yyyy')}: [/][#fff font-size: 15px]{valueY.formatNumber('#.0')}%[/] [#fff]{additional}[/]";

        var circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#FFC000");
        circle.strokeWidth = 1;

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer; 
        chart.legend = new am4charts.Legend(); 
    },
    beforeDestroy() {
        if (this.chart){
            this.chart.dispose();
        }
  }
}
</script>

<style scoped>
.grafica {
  width: 100%;
  height: 500px;
}
</style>
