import { createApp } from 'vue';
import IndicadorGraficaComponent from './components/main/IndicadorGraficaComponent';
import LineColumnSeguridadPublica from './components/main/LineColumnSeguridadPublica';
import LineColumnSegPubPer from './components/main/LineColumnSegPubPer';
import LineColumn from './components/main/LineColumn';

const app = createApp({
    components: {
        IndicadorGraficaComponent,
        LineColumnSeguridadPublica,
        LineColumnSegPubPer,
        LineColumn,
    }
})
app.mount('#main');