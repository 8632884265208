<template>
    <div class="grafica" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH345078589");
export default {
    props:{
        info_grafica:{
            type: Array,
            required: true,
        },
        indicador_id:{
            type: Number,
            required: true,
        }
    },
    mounted() {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "periodo";
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.title.fontSize = 17;
        categoryAxis.title.fontWeight  = "bold";
        var label = categoryAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 300;
        label.tooltipText = "{periodo}";

        if(this.indicador_id>=1 && this.indicador_id<= 4)
        {
            categoryAxis.title.text = "Trimestre / Año";
            categoryAxis.events.on("sizechanged", function(ev) {
            var axis = ev.target;
            var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            if (cellWidth < axis.renderer.labels.template.maxWidth) {
                axis.renderer.labels.template.rotation = -80;
                axis.renderer.labels.template.horizontalCenter = "right";
                axis.renderer.labels.template.verticalCenter = "middle";
            }
            else {
                axis.renderer.labels.template.rotation = 0;
                axis.renderer.labels.template.horizontalCenter = "middle";
                axis.renderer.labels.template.verticalCenter = "top";
            }
            });
        }
        else{
            categoryAxis.title.text = "Año";
        }
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.title.fontSize = 17;
        valueAxis.title.fontWeight  = "bold";
        valueAxis.title.text = "Porcentaje %";
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = "Porcentaje";
        columnSeries.dataFields.valueY = "porcentaje";
        columnSeries.dataFields.categoryX = "periodo";
        columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name} {categoryX}:\n[/][#fff font-size: 20px]{valueY}%[/] [#fff]{additional}[/]"
        columnSeries.columns.template.propertyFields.fill = "color";
        columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries.columns.template.propertyFields.stroke = "color";
        columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
        columnSeries.columns.template.width = am4core.percent(90);
        columnSeries.columns.template.strokeWidth = 1;
        columnSeries.tooltip.label.textAlign = "middle";
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = "Porcentaje";
        lineSeries.dataFields.valueY = "porcentaje";
        lineSeries.dataFields.categoryX = "periodo";
        lineSeries.stroke = am4core.color("#03A5FB");
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";
        var bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#03A5FB");
        var circle = bullet.createChild(am4core.Circle);
        circle.radius = 3;
        circle.fill = am4core.color("#03A5FB");
        circle.strokeWidth = 1;
        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer; 
        chart.data = this.info_grafica;
    },
    beforeDestroy() {
        if (this.chart){
            this.chart.dispose();
        }
  }
}
</script>

<style scoped>
.grafica {
  width: 100%;
  height: 500px;
}
</style>
